<template>
<Breadcrumb page-title="Configure Agent Email" page-section="Settings"> </Breadcrumb>
  <section class="content">
    <div class="row">
        <div class="col-md-6">
            <div class="form-group">
                <label for="inputPassword3" class="col-12 control-label text-left">Target Segment</label>
                <div class="col-12">
                    <Multiselect
                            v-model="value"
                            placeholder="Select Segment"
                            label="name"
                            :searchable="true"
                            :options="[
                                { value: 'iService 1', name: 'iService',level:'1'}
                            ]"
                            >
                            <template v-slot:option="{ option }">
                                <span v-bind:class="'op-level-' + option.level"> {{ option.name }} </span>
                            </template>
                        </Multiselect>
                </div>
            </div>
            <div class="form-group">
                <div class="checkbox">
                    <label>
                    <Toggle/> Expect Customer reply (Default)
                    </label>
                </div>
            </div>
        </div>
    </div>

       <div class="row form-horizontal">
       <div class="col-md-12">
         <div class="box box-primary">
            <div class="box-header with-border">
                <h3 class="box-title">Mailbox Assignment</h3>
            </div>
              <div class="box-body">
           <div class="row form-horizontal">
              <div class="col-md-12">
                <div class="form-group"><label class="col-2 control-label text-left data-label">Topic</label><label class="col-6 control-label text-left data-label">Mailbox</label></div>
              </div>
              <div class="col-md-12">
                <div class="form-group">
                    <label class="col-2 control-label text-left">All Topics</label>
                    <div class="col-6">
                        <Multiselect
                            v-model="value"
                            placeholder="-Select Mailbox-"
                            label="name"
                            :searchable="true"
                            :options="[
                                { value: 'Jhone Doe', name: 'Jhone Doe',level:'1'}
                            ]"
                            >
                            <template v-slot:option="{ option }">
                                <span v-bind:class="'op-level-' + option.level"> {{ option.name }} </span>
                            </template>
                        </Multiselect>
                    </div>
                    <div class="col-2"><button class="icon-button btn btn-md btn-link button-delete data-v-tooltip" type="button" data-v-tooltip="Remove Mailbox" style="--v-tooltip-arrow-display: inline; --v-tooltip-left: 50%; --v-tooltip-top: 100%; --v-tooltip-translate: translate(-50%, 10%); --v-tooltip-arrow-border-color: transparent transparent var(--v-tooltip-background-color) transparent; --v-tooltip-arrow-top: calc(var(--v-tooltip-top) - var(--v-tooltip-top-offset) - 7px);"><i class="fa fa-times-circle"></i></button></div>
                </div>
                <div class="form-group">
                    <label class="col-2 control-label text-left">Sales</label>
                    <div class="col-6">
                        <Multiselect
                            v-model="value"
                            placeholder="-Select Mailbox-"
                            label="name"
                            :searchable="true"
                            :options="[
                                { value: 'Jhone Doe', name: 'Jhone Doe',level:'1'}
                            ]"
                            >
                            <template v-slot:option="{ option }">
                                <span v-bind:class="'op-level-' + option.level"> {{ option.name }} </span>
                            </template>
                        </Multiselect>
                    </div>
                    <div class="col-2"><button class="icon-button btn btn-md btn-link button-delete data-v-tooltip" type="button" data-v-tooltip="Remove Mailbox" style="--v-tooltip-arrow-display: inline; --v-tooltip-left: 50%; --v-tooltip-top: 100%; --v-tooltip-translate: translate(-50%, 10%); --v-tooltip-arrow-border-color: transparent transparent var(--v-tooltip-background-color) transparent; --v-tooltip-arrow-top: calc(var(--v-tooltip-top) - var(--v-tooltip-top-offset) - 7px);"><i class="fa fa-times-circle"></i></button></div>
                    </div>
              </div>
              </div>

           </div>
        </div>
       </div>
    </div>

       <div class="box box-primary">
        <div class="box-header with-border">
            <h3 class="box-title">Add Lists</h3>
            <div class="pull-right">
                
            </div>
        </div>
        <div class="box-body">
            <div class="row">
               
                    <div class="col-md-4">
                      <div class="form-group">
                        <label class="control-label text-left">List Name</label>
                        <input iservice-id="Name" type="text" class="form-control" placeholder="List Name">
                        <span class="text-red"><i class="fa fa-warning"></i> Please enter list name.</span>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="form-group">
                        <label class="control-label text-left">Email Addresses</label>
                        <textarea class="form-control" placeholder="Enter List of Your Email Addresses"></textarea>
                        <span class="text-red"><i class="fa fa-warning"></i> Please select email address</span>
                      </div>
                    </div>
                    <div class="col-md-4 mbdefault-delete-row">
                     <div class="form-group">
                         <label class="control-label text-left">&nbsp;</label>
                         <div class="clear"></div>
                         <button class="btn btn-primary mtop-30" type="button"> Add a list </button>
                    </div>
                </div>
                
            </div>
        </div>
    </div>

    <div class="row form-horizontal">
       <div class="col-md-12">
         <div class="box box-primary">
            <div class="box-header with-border">
                <h3 class="box-title">Mailing Lists</h3>
            </div>
            <div class="box-body">
              <div class="notdatatable form-inline mbottom-15">
                <div class="table-controls table-columns">
                    <div class="btn-group"><button class="btn btn-default" type="button"><span>Excel</span></button></div>
                </div>
                <div class="table-controls table-pagesize table-columns">
                    <label>
                        Show 
                        <select class="form-control input-xs">
                            <option>5</option>
                            <option>10</option>
                            <option>25</option>
                            <option>50</option>
                            <option>100</option>
                            <option>250</option>
                            <option>500</option>
                            <option>1000</option>
                        </select>
                        entries 
                    </label>
                </div>
                <div class="table-controls table-filter">Filter List <input type="search" class="form-control" id="Main_MyDashboard_Recent_TableControls_Filter"></div>
                </div>
                <table class="table table-striped">
                <thead>
                    <tr role="row">
                        <th class="no-sort" name="name" tabindex="0" aria-controls="historyTabCaseView" rowspan="1" colspan="1"></th>
                        <th class="sorting">
                            List Name
                        </th>
                       
                        <th class="sorting">
                            Email Addresses
                        </th>
                    
                        <th>
                            &nbsp;
                        </th>
                    
                    </tr>
                </thead>
                    
                    <tbody>
                    <tr role="row">
                        <td name="name" class="col-icon-check sorting_1">
                        
                            <button class="btn btn-xs btn-link text-default" v-on:click="editrow1 = !editrow1" v-show="!editrow1">
                                <i class="fa fa-pencil"></i>
                            </button>
                        </td>
                        <td>
                            <span v-show="!editrow1">
                            _Out of Office Notices
                            </span>
                            <input type="text" class="form-control inline-form-control" value="_Out of Office Notices" v-show="editrow1">
                        </td>
                        <td>
                            <a href="" v-show="!editrow1">jhon.doe@example.com</a>
                            <div v-show="editrow1">
                              <textarea class="form-control" placeholder="Enter List of Your Email Addresses"></textarea>
                            </div>
                        </td>
                       
                        <td>
                            <button class="btn button-delete" v-show="!editrow1">
                                    <i class="fa fa-times-circle"></i>
                            </button>
                            <button class="btn btn-xs btn-primary" v-show="editrow1">
                                    Save
                            </button>
                            <button class="btn btn-xs btn-link" v-show="editrow1" v-on:click="editrow1 = !editrow1">
                                    Cancel
                            </button>
                        </td>
                        
                    </tr>
                       
                        <tr role="row">
                                <td name="name" class="col-icon-check sorting_1">
                                 
                                    <button class="btn btn-xs btn-link text-default" v-on:click="editrow2 = !editrow2" v-show="!editrow2">
                                        <i class="fa fa-pencil"></i>
                                    </button>
                                </td>
                            <td>
                                <span v-show="!editrow2">
                                Call Center Management
                                </span>
                                <input type="text" class="form-control inline-form-control" value="Call Center Management" v-show="editrow2">
                        
                            </td>
                            <td>
                                <a href="" v-show="!editrow2">jhon.doe@example.com</a>
                               <div v-show="editrow2">
                                <textarea class="form-control" placeholder="Enter List of Your Email Addresses"></textarea>
                                </div>
                            </td>
                           
                            <td>
                                <button class="btn button-delete" v-show="!editrow2">
                                        <i class="fa fa-times-circle"></i>
                                </button>
                                <button class="btn btn-xs btn-primary" v-show="editrow2">
                                        Save
                                </button>
                                <button class="btn btn-xs btn-link" v-show="editrow2" v-on:click="editrow2 = !editrow2">
                                        Cancel
                                </button>
                            </td>
                            
                        </tr>
                      
                        <tr role="row">
                                <td name="name" class="col-icon-check sorting_1">
                                
                                    <button class="btn btn-xs btn-link text-default" v-on:click="editrow3 = !editrow3" v-show="!editrow3">
                                        <i class="fa fa-pencil"></i>
                                    </button>
                                </td>
                                <td>
                                <span v-show="!editrow3">
                               Financial Services
                                </span>
                                <input type="text" class="form-control inline-form-control" value="Financial Services" v-show="editrow3">
                    
                                </td>
                                <td>
                                    <a href="" v-show="!editrow3">jhon.doe@example.com</a>
                                    <div v-show="editrow3">
                                        <textarea class="form-control" placeholder="Enter List of Your Email Addresses"></textarea>
                                    </div>
                                </td>
                               
                            <td>
                                <button class="btn button-delete" v-show="!editrow3">
                                        <i class="fa fa-times-circle"></i>
                                </button>
                                <button class="btn btn-xs btn-primary" v-show="editrow3">
                                        Save
                                </button>
                                <button class="btn btn-xs btn-link" v-show="editrow3" v-on:click="editrow3 = !editrow3">
                                        Cancel
                                </button>
                            </td>
                        
                            
                        </tr>
                        <tr class="expandedrowcontent  bg-agent-reply" v-bind:class="replyType" v-if="showexpanded3">
                            <td class="white-bg">&nbsp;</td>
                            <td colspan="6">
                                <KbArticleDetail></KbArticleDetail>
                            </td> 
                        </tr>
                </tbody>
                </table>
                <div id="Main_MyDashboard_Recent_TableInfo">
                <div class="notdatatable table-info">Showing 1 to 3 of 3 entries</div>
                <div class="dataTables_paginate notdatatable table-pagination text-end">
                    <ul class="pagination">
                        <li><a href="">Previous</a></li>
                        <li class="active"><a href="">1</a></li>

                        <li><a href="">Next</a></li>
                    </ul>
                </div>
                </div>
            </div>
        </div>
       </div>
    </div>

 
</section>
</template>
<style src="@vueform/toggle/themes/default.css"></style>
<script>
import Breadcrumb from '../components/Breadcrumb.vue';
import Toggle from '@vueform/toggle'
import iServiceSwitch from '../components/iServiceSwitch.vue';
import KbArticleDetail from '../components/KbArticleDetail.vue';

import Multiselect from '@vueform/multiselect'
import Vue from 'vue';
import { reactive,ref, onMounted } from 'vue'
export default {
  name: 'MailingMessages Settings',
   components: {
    Breadcrumb,
    Toggle,
    iServiceSwitch,
    Multiselect,
    KbArticleDetail
  },
   data: function(){
          return{
            editrow1 : false,
            editrow2 : false,
            editrow3 : false
          }
      },
  setup(){      
    }

}
</script>
